import clsx from "clsx";
import { HEALTH } from "./utils";
import { Icon } from "./Icon";
import { useRecoilValue } from "recoil";
import { healthAtom } from "./state";

export const Health = () => {
  const health = useRecoilValue(healthAtom);

  return (
    <div className="h-7 flex flex-row bg-clement-1 items-center rounded-full gap-x-1 px-2">
      {Array.from(Array(HEALTH)).map((_, index) => (
        <span
          key={`health-${index}`}
          className={clsx(
            "w-[18px]",
            index < health ? "text-clement-3" : "text-clement-1"
          )}
        >
          <Icon id="favorite" />
        </span>
      ))}
    </div>
  );
};
