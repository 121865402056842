import clsx from "clsx";
import { Icon } from "./Icon";
import { useRecoilState } from "recoil";
import { isReadyAtom } from "./state";

export const HowToPlayModal = () => {
  const [isReady, setIsReady] = useRecoilState(isReadyAtom);

  if (isReady) return null;

  return (
    <div className="absolute inset-0 w-full h-full bg-clement-7/90 flex items-center justify-center p-4">
      <div className="w-full max-w-[48rem] bg-clement-1 flex flex-col items-center p-6 border-[4px] border-clement-8 shadow-height shadow-clement-8 rounded-lg text-clement-8 text-sm">
        <div className="text-2xl font-bold mb-4">How to play</div>
        <div className="flex flex-row portrait:flex-col gap-x-4 portrait:gap-x-0 portrait:gap-y-2">
          <div className="flex-1 flex flex-col items-start portrait:items-center portrait:text-center">
            <p className="mb-2">
              {
                "Decode multiple 4-tile spells before time or in 4 turns to avoid losing a life. Lose all lives, and it's game over!"
              }
            </p>
            <p className="mb-4">
              {
                "The tile colors shift to reflect the proximity of your guess to the combination."
              }
            </p>
          </div>
          <div className="flex-1 flex flex-col items-start portrait:items-center">
            <p className="font-bold mb-2 landscape:hidden">Example:</p>
            <div className="flex flex-row items-center justify-center gap-x-1.5 mb-2 select-none">
              <div className="h-8 w-8 flex items-center justify-center border-2 border-clement-8 rounded-md bg-clement-5">
                <span className="w-[18px] text-clement-8">
                  <Icon id="a" />
                </span>
              </div>
              <div className="h-8 w-8 flex items-center justify-center border-2 border-clement-8 rounded-md bg-clement-2">
                <span className="w-[18px] text-clement-8">
                  <Icon id="x" />
                </span>
              </div>
              <div className="h-8 w-8 flex items-center justify-center border-2 border-clement-8 rounded-md bg-clement-2">
                <span className="w-[18px] text-clement-8">
                  <Icon id="a" />
                </span>
              </div>
              <div className="h-8 w-8 flex items-center justify-center border-2 border-clement-8 rounded-md bg-clement-3">
                <span className="w-[18px] text-clement-8">
                  <Icon id="b" />
                </span>
              </div>
            </div>
            <div className="text-sm text-clement-8 text-center mb-1">
              <span>{"• "}</span>
              <span className="w-[18px] inline-block text-clement-8 translate-y-1">
                <Icon id="a" />
              </span>
              <span>{" is in the correct position within the spell."}</span>
            </div>
            <div className="text-sm text-clement-8 text-center mb-1">
              <span>{"• "}</span>
              <span className="w-[18px] inline-block text-clement-8 translate-y-1">
                <Icon id="x" />
              </span>
              <span>{" and an additional "}</span>
              <span className="w-[18px] inline-block text-clement-8 translate-y-1">
                <Icon id="a" />
              </span>
              <span>{" are misplaced."}</span>
            </div>
            <div className="text-sm text-clement-8 text-center mb-5">
              <span>{"• "}</span>
              <span className="w-[18px] inline-block text-clement-8 translate-y-1">
                <Icon id="b" />
              </span>
              <span>{" is not part of the spell."}</span>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-row portrait:flex-col portrait:gap-y-6 items-center">
          <div className="flex-1 text-sm text-clement-8">
            <span>{"Made with "}</span>
            <span className="w-[18px] inline-block text-clement-3 translate-y-1">
              <Icon id="favorite" />
            </span>
            <span>{" by "}</span>
            <a
              href="https://twitter.com/hugoasduarte"
              target="_blank"
              className="font-bold text-clement-6 hover:underline"
              rel="noreferrer"
            >
              Hugo Duarte
            </a>
          </div>

          <div className="flex-1">
            <button
              onClick={() => setIsReady(true)}
              className={clsx(
                "h-12 min-w-32 px-6 flex items-center justify-center bg-clement-1 text-clement-8 tracking-wide rounded-lg select-none",
                "border-[4px] border-clement-8 shadow-clement-8 shadow-height",
                "active:shadow-none active:translate-x-1 active:translate-y-1"
              )}
            >
              PLAY
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
