import { atom, selector } from "recoil";
import { HEALTH, Key, getRandomCombination } from "./utils";

export const combinationAtom = atom({
  key: "combination",
  default: getRandomCombination(),
});

export const turnsAtom = atom<Key[][]>({
  key: "turns",
  default: [],
});

export const currentIndexAtom = atom({
  key: "currentIndex",
  default: 0,
});

export const currentTurnAtom = atom<Key[]>({
  key: "currentTurn",
  default: [],
});

export const showGameOverAtom = atom({
  key: "showGameOver",
  default: false,
});

export const timerAtom = atom({
  key: "timer",
  default: 1,
});

export const healthAtom = atom({
  key: "health",
  default: HEALTH,
});

export const scoreAtom = atom({
  key: "score",
  default: 0,
});

export const hasStartedAtom = atom({
  key: "hasStarted",
  default: false,
});

export const isGameStartedAtom = atom({
  key: "isGameStarted",
  default: false,
});

export const turnWonAtom = atom({
  key: "turnWon",
  default: false,
});

export const isReadyAtom = atom({
  key: "isReady",
  default: false,
});

export const guessedCombinationAtom = selector({
  key: "guessedCombination",
  get: ({ get }) => {
    const combination = get(combinationAtom);
    const turns = get(turnsAtom);
    return combination.map((x, i) => {
      const wasGuessed = turns.some((row) => row[i] === x);
      if (wasGuessed) return x;
      return null;
    });
  },
});

export const orientationAtom = atom<"portrait" | "landscape">({
  key: "orientation",
  default: "landscape",
});
