export const KEYS = [
  "up",
  "down",
  "a",
  "b",
  "left",
  "right",
  "x",
  "y",
] as const;
export type Key = (typeof KEYS)[number];
export type MutableNumberRef = {
  value: number;
};

export const TURNS = 4;
export const COLUMNS = 4;
export const TIMER = 30 * 1000;
export const HEALTH = 3;

export const ROW_SCORE = [1000, 500, 200, 100];

export const getRandomCombination = () =>
  Array.from(Array(COLUMNS)).map(
    () => KEYS[Math.floor(Math.random() * KEYS.length)]
  );
