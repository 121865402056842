import { useCallback, useEffect, useRef, useState } from "react";

import { GameKeys } from "./GameKeys";
import {
  COLUMNS,
  HEALTH,
  Key,
  ROW_SCORE,
  TURNS,
  getRandomCombination,
} from "./utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  combinationAtom,
  currentIndexAtom,
  currentTurnAtom,
  guessedCombinationAtom,
  hasStartedAtom,
  healthAtom,
  isGameStartedAtom,
  isReadyAtom,
  orientationAtom,
  scoreAtom,
  showGameOverAtom,
  timerAtom,
  turnWonAtom,
  turnsAtom,
} from "./state";
import { GameTurns } from "./GameTurns";
import { HowToPlayModal } from "./HotToPlayModal";
import { Health } from "./Health";
import { Score } from "./Score";
import { GameOverModal } from "./GameOverModal";
import { Timer } from "./Timer";
import clsx from "clsx";

const LEFT_SYMBOLS: Key[] = ["a", "b", "x", "y"];
const RIGHT_SYMBOLS: Key[] = ["up", "down", "left", "right"];

export default function Game() {
  const [combination, setCombination] = useRecoilState(combinationAtom);
  const [turns, setTurns] = useRecoilState(turnsAtom);
  const [currentIndex, setCurrentIndex] = useRecoilState(currentIndexAtom);
  const [currentTurn, setCurrentTurn] = useRecoilState(currentTurnAtom);
  const [showGameOver, setShowGameOver] = useRecoilState(showGameOverAtom);
  const setTimer = useSetRecoilState(timerAtom);
  const [health, setHealth] = useRecoilState(healthAtom);
  const setScore = useSetRecoilState(scoreAtom);
  const [hasStarted, setHasStarted] = useRecoilState(hasStartedAtom);
  const [isGamePaused, setIsGamePaused] = useRecoilState(isGameStartedAtom);
  const setTurnWon = useSetRecoilState(turnWonAtom);
  const isReady = useRecoilValue(isReadyAtom);
  const guessedCombination = useRecoilValue(guessedCombinationAtom);
  const [orientation, setOrientation] = useRecoilState(orientationAtom);

  const timerRef = useRef(1);

  const handleFailedTurn = useCallback(() => {
    setIsGamePaused(true);
    setHealth((prev) => prev - 1);
  }, []);

  const handleNewTurn = useCallback(() => {
    setIsGamePaused(false);
    setCombination(getRandomCombination());
    setTurns([]);
    setCurrentIndex(0);
    setCurrentTurn([]);
    setTimer(1);
    timerRef.current = 1;
    setTurnWon(false);
  }, []);

  // Handle game paused
  useEffect(() => {
    if (!isGamePaused) return;
    if (showGameOver) return;

    const timeout = setTimeout(handleNewTurn, 2000);
    return () => clearTimeout(timeout);
  }, [isGamePaused, handleNewTurn, showGameOver]);

  // Handle end of line
  useEffect(() => {
    if (currentTurn.length < COLUMNS) return;

    const correctCols = currentTurn.filter((key, i) => key === combination[i]);
    const isCorrect = correctCols.length === COLUMNS;
    const hasFailed = !isCorrect && currentIndex + 1 === TURNS;

    if (hasFailed) {
      handleFailedTurn();
    } else if (isCorrect) {
      setIsGamePaused(true);
      const rowScore = ROW_SCORE[currentIndex];
      const timerScore = Math.floor(1000 * timerRef.current);
      setScore((prev) => prev + rowScore + timerScore);
      setTurnWon(true);
    } else {
      setScore((prev) => prev + 10 * correctCols.length);
    }

    setTurns((prev) => [...prev, currentTurn]);
    setCurrentIndex((prev) => prev + 1);
    setCurrentTurn([]);
  }, [currentTurn, handleFailedTurn]);

  // Handle no health
  useEffect(() => {
    if (health > 0) return;
    setShowGameOver(true);
  }, [health]);

  const handleRestart = useCallback(() => {
    setHasStarted(false);
    setScore(0);
    setShowGameOver(false);
    setHealth(HEALTH);
    handleNewTurn();
  }, [handleNewTurn]);

  const handleClear = useCallback(() => setCurrentTurn([]), []);

  const handleKeyPress = useCallback(
    (id: Key) => {
      if (isGamePaused) return;
      setCurrentTurn((prev) => [...prev, id]);
      if (!hasStarted) setHasStarted(true);
    },
    [isGamePaused, hasStarted]
  );

  useEffect(() => {
    if (!isReady) return;

    const KEYS_MAP: Record<string, Key> = {
      KeyW: "a",
      KeyA: "x",
      KeyS: "b",
      KeyD: "y",
      ArrowUp: "up",
      ArrowLeft: "left",
      ArrowDown: "down",
      ArrowRight: "right",
    };

    const handleEvents = (event: KeyboardEvent) => {
      const isValidKey = Object.keys(KEYS_MAP).includes(event.code);
      if (event.code === "Space") handleClear();
      if (!isValidKey) return;
      handleKeyPress(KEYS_MAP[event.code]);
    };

    window.addEventListener("keydown", handleEvents);
    return () => window.removeEventListener("keydown", handleEvents);
  }, [isReady, handleKeyPress, handleClear]);

  useEffect(() => {
    const handleRotation = () => {
      const type = window.screen.orientation.type;
      const isLandscape = ["landscape-primary", "landscape-secondary"].includes(
        type
      );
      setOrientation(isLandscape ? "landscape" : "portrait");
    };

    handleRotation();
    window.addEventListener("orientationchange", handleRotation);
    return () =>
      window.removeEventListener("orientationchange", handleRotation);
  }, []);

  const colIndex = currentTurn.length;
  const currentGuessed = guessedCombination[colIndex];

  return (
    <>
      {orientation === "portrait" ? (
        // PORTRAIT
        <div className="h-full w-full flex flex-col items-center justify-between bg-clement-7 font-poppins select-none p-4">
          <div className="w-full flex flex-row items-center justify-between">
            <Health />
            <Score />
          </div>
          <div className="w-[19rem] pt-4 flex flex-col gap-y-4">
            <Timer timerRef={timerRef} onTimeout={handleFailedTurn} />
            <GameTurns />
          </div>
          <div className="flex flex-col items-center gap-y-4">
            <button
              onClick={handleClear}
              className={clsx(
                "h-12 px-6 flex items-center justify-center bg-clement-1 text-clement-8 tracking-wide rounded-lg",
                "border-[4px] border-clement-8 shadow-clement-8 shadow-height",
                "active:shadow-none active:translate-x-1 active:translate-y-1"
              )}
            >
              CLEAR
            </button>
            <div className="flex flex-row gap-x-4">
              <GameKeys
                keys={LEFT_SYMBOLS}
                guessed={currentGuessed}
                onClick={(id) => handleKeyPress(id)}
              />
              <GameKeys
                keys={RIGHT_SYMBOLS}
                guessed={currentGuessed}
                onClick={(id) => handleKeyPress(id)}
              />
            </div>
          </div>
        </div>
      ) : (
        // LANDSCAPE
        <div className="h-full w-full flex flex-col items-center justify-between bg-clement-7 font-poppins select-none p-6">
          <div className="w-full h-full flex items-end justify-between gap-x-4">
            <div className="flex flex-1 h-full flex-col items-start justify-between gap-y-4">
              <Health />
              <div className="flex-1 w-full flex flex-col justify-end items-start lg:items-center">
                <GameKeys
                  keys={LEFT_SYMBOLS}
                  guessed={currentGuessed}
                  onClick={(id) => handleKeyPress(id)}
                />
              </div>
            </div>
            <div className="w-[13.5rem] landscape:lg:w-[19rem] flex flex-col gap-y-4">
              <Timer timerRef={timerRef} onTimeout={handleFailedTurn} />
              <GameTurns />
            </div>
            <div className="flex flex-1 h-full flex-col items-end justify-between">
              <Score />
              <div className="flex-1 w-full flex flex-col justify-end items-end lg:items-center gap-y-4">
                <button
                  onClick={handleClear}
                  className={clsx(
                    "relative h-12 w-36 lg:w-56 px-6 flex items-center justify-center bg-clement-1 text-clement-8 tracking-wide rounded-lg",
                    "border-[4px] border-clement-8 shadow-clement-8 shadow-height",
                    "active:shadow-none active:translate-x-1 active:translate-y-1"
                  )}
                >
                  <span>CLEAR</span>
                  <div className="absolute -top-0.5 right-1 text-clement-8/30 font-bold hidden lg:block">
                    Space
                  </div>
                </button>
                <GameKeys
                  guessed={currentGuessed}
                  keys={RIGHT_SYMBOLS}
                  onClick={(id) => handleKeyPress(id)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <HowToPlayModal />
      <GameOverModal onRestart={handleRestart} />
    </>
  );
}
