import { Icon } from "./Icon";
import { useRecoilValue } from "recoil";
import { scoreAtom } from "./state";

export const Score = () => {
  const score = useRecoilValue(scoreAtom);

  return (
    <div className="h-7 px-2 bg-clement-1 w-[9rem] rounded-full flex flex-row items-center">
      <span className="w-[18px] text-clement-8">
        <Icon id="trophy" />
      </span>
      <div className="flex flex-1 items-center justify-center text-clement-8">
        {score}
      </div>
    </div>
  );
};
