import { ReactComponent as Favorite } from "./assets/favorite.svg";
import { ReactComponent as Trophy } from "./assets/trophy.svg";
import { ReactComponent as ArrowLeft } from "./assets/arrow_back.svg";
import { ReactComponent as ArrowUpward } from "./assets/arrow_upward.svg";
import { ReactComponent as ArrowDownward } from "./assets/arrow_downward.svg";
import { ReactComponent as ArrowForward } from "./assets/arrow_forward.svg";
import { ReactComponent as Heat } from "./assets/mode_heat.svg";
import { ReactComponent as WaterDrop } from "./assets/water_drop.svg";
import { ReactComponent as Bolt } from "./assets/bolt.svg";
import { ReactComponent as Leaf } from "./assets/nest_eco_leaf.svg";
import { Key } from "./utils";

type Id = Key | "trophy" | "favorite";

const SYMBOLS: { id: Id; icon: JSX.Element }[] = [
  { id: "left", icon: <ArrowLeft /> },
  { id: "up", icon: <ArrowUpward /> },
  { id: "down", icon: <ArrowDownward /> },
  { id: "right", icon: <ArrowForward /> },
  { id: "a", icon: <Heat /> },
  { id: "b", icon: <WaterDrop /> },
  { id: "x", icon: <Bolt /> },
  { id: "y", icon: <Leaf /> },
  { id: "trophy", icon: <Trophy /> },
  { id: "favorite", icon: <Favorite /> },
];

type Props = {
  id: Id;
};

export const Icon = ({ id }: Props) => {
  const data = SYMBOLS.find((x) => x.id === id);
  if (!data) return null;
  return data.icon;
};
