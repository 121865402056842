import clsx from "clsx";
import { Key } from "./utils";
import { Icon } from "./Icon";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { orientationAtom } from "./state";

const KEY_MAP: Record<Key, string> = {
  up: "",
  down: "",
  left: "",
  right: "",
  a: "W",
  b: "S",
  x: "A",
  y: "D",
};

type Props = {
  keys: Key[];
  guessed: Key | null;
  onClick: (id: Key) => void;
};

export const GameKeys = ({ keys, guessed, onClick }: Props) => {
  const orientation = useRecoilValue(orientationAtom);

  const rows = useMemo(() => {
    const isPortait = orientation === "portrait";
    const isLg = window.innerWidth >= 1024;
    if (isPortait || !isLg) return [keys.slice(0, 2), keys.slice(2)];
    return [[keys[0]], [keys[2], keys[1], keys[3]]];
  }, [keys]);

  return (
    <div className="flex flex-col items-center gap-y-4">
      {rows.map((row, rowIndex) => {
        return (
          <div
            key={`row-${rowIndex}-${keys.toString()}`}
            className="flex flex-row gap-x-4"
          >
            {row.map((key) => (
              <button
                key={`input-${key}`}
                onClick={() => onClick(key)}
                className={clsx(
                  "relative w-16 h-16 flex items-center justify-center bg-clement-1 rounded-lg",
                  "border-[4px] border-clement-8 shadow-clement-8 shadow-height",
                  "active:shadow-none active:translate-x-1 active:translate-y-1",
                  { "bg-clement-5": guessed === key }
                )}
              >
                <span className="w-8 text-clement-8">
                  <Icon id={key} />
                </span>
                <div className="absolute -top-0.5 right-1 text-clement-8/30 font-bold hidden lg:block">
                  {KEY_MAP[key]}
                </div>
              </button>
            ))}
          </div>
        );
      })}
    </div>
  );
};
