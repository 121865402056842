import clsx from "clsx";
import { MutableRefObject, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { TIMER } from "./utils";
import { hasStartedAtom, isGameStartedAtom, timerAtom } from "./state";

type Props = {
  timerRef: MutableRefObject<number>;
  onTimeout: () => void;
};

export const Timer = ({ timerRef, onTimeout }: Props) => {
  const [timer, setTimer] = useRecoilState(timerAtom);
  const hasStarted = useRecoilValue(hasStartedAtom);
  const isGamePaused = useRecoilValue(isGameStartedAtom);

  // Handle timer
  const FRAMES_UPDATE = 10;
  useEffect(() => {
    if (!hasStarted) return;
    if (isGamePaused) return;

    const timeout = setTimeout(() => {
      const newTime = timer - 1 / (TIMER / FRAMES_UPDATE);
      if (newTime <= 0) onTimeout();
      setTimer(newTime);
      timerRef.current = newTime;
    }, FRAMES_UPDATE);

    return () => clearTimeout(timeout);
  }, [hasStarted, onTimeout, isGamePaused, timer]);

  return (
    <div className="h-6 w-full rounded-xl flex flex-row justify-start bg-clement-8 overflow-hidden border-[4px] border-clement-8">
      <div
        className={clsx("h-full rounded-xl", {
          "bg-clement-5": timer >= 0.5,
          "bg-clement-2": timer >= 0.2 && timer < 0.5,
          "bg-clement-3": timer >= 0 && timer < 0.2,
        })}
        style={{ width: `${timer * 100}%` }}
      />
    </div>
  );
};
