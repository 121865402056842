import clsx from "clsx";
import { COLUMNS, Key, TURNS } from "./utils";
import { Icon } from "./Icon";
import { useRecoilValue } from "recoil";
import {
  combinationAtom,
  currentIndexAtom,
  currentTurnAtom,
  turnWonAtom,
  turnsAtom,
} from "./state";

export const GameTurns = () => {
  const combination = useRecoilValue(combinationAtom);
  const turns = useRecoilValue(turnsAtom);
  const currentIndex = useRecoilValue(currentIndexAtom);
  const currentTurn = useRecoilValue(currentTurnAtom);
  const turnWon = useRecoilValue(turnWonAtom);

  return (
    <div className="flex-1 flex flex-col items-center justify-center gap-y-4 landscape:gap-y-2 landscape:lg:gap-y-4">
      {Array.from(Array(TURNS)).map((_, turnIndex) => {
        const isCurrentTurn = turnIndex === currentIndex;
        const isPastTurn = !!turns[turnIndex];

        return (
          <div
            key={`game-row-${turnIndex}`}
            className="flex flex-row gap-x-4 landscape:gap-x-2 landscape:lg:gap-x-4"
          >
            {Array.from(Array(COLUMNS)).map((_, colIndex) => {
              let key: Key | null = null;
              let state: "correct" | "partial" | "incorrect" = "incorrect";

              if (isCurrentTurn) {
                key = currentTurn[colIndex] || null;
              } else if (isPastTurn) {
                const thisKey = turns[turnIndex][colIndex];
                key = thisKey;

                if (!combination.includes(thisKey)) {
                  state = "incorrect";
                } else {
                  if (thisKey === combination[colIndex]) {
                    state = "correct";
                  } else {
                    const incorrect = combination.filter(
                      (c, i) => c !== turns[turnIndex][i]
                    );
                    const hasRemaining = incorrect.includes(thisKey);
                    if (hasRemaining) {
                      state = "partial";
                    }
                  }
                }
              }

              return (
                <div
                  key={`game-col-${colIndex}`}
                  className={clsx(
                    "w-16 h-16 landscape:w-12 landscape:h-12 landscape:lg:w-16 landscape:lg:h-16 flex items-center justify-center bg-clement-1 rounded-lg",
                    "border-none border-clement-8",
                    {
                      "bg-clement-5": isPastTurn && state === "correct",
                      "bg-clement-2": isPastTurn && state === "partial",
                      "bg-clement-3": isPastTurn && state === "incorrect",
                      "opacity-30":
                        turnIndex > currentIndex - (turnWon ? 1 : 0),
                    }
                  )}
                >
                  {key && (
                    <span className="w-8 text-clement-8">
                      <Icon id={key} />
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
