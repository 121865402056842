import clsx from "clsx";
import { useRecoilValue } from "recoil";
import { scoreAtom, showGameOverAtom } from "./state";

type Props = {
  onRestart: () => void;
};

export const GameOverModal = ({ onRestart }: Props) => {
  const showGameOver = useRecoilValue(showGameOverAtom);
  const score = useRecoilValue(scoreAtom);

  if (!showGameOver) return null;

  return (
    <div className="absolute inset-0 w-full h-full bg-clement-7/90 flex items-center justify-center">
      <div className="w-full max-w-72 bg-clement-1 flex flex-col items-center p-6 border-[4px] border-clement-8 shadow-height shadow-clement-8 rounded-lg gap-y-6 select-none">
        <div className="text-2xl font-bold text-clement-8">Game Over</div>
        <div className="flex flex-col items-center">
          <div className="text-lg font-bold text-clement-7">Total Score</div>
          <div className="text-3xl font-bold text-clement-8">{score}</div>
        </div>
        <button
          onClick={onRestart}
          className={clsx(
            "h-12 px-6 flex items-center justify-center bg-clement-1 text-clement-8 tracking-wide rounded-lg",
            "border-[4px] border-clement-8 shadow-clement-8 shadow-height",
            "active:shadow-none active:translate-x-1 active:translate-y-1"
          )}
        >
          TRY AGAIN
        </button>
      </div>
    </div>
  );
};
